export default {
     namespaced:true,
     state:{
       userName:""
     },
     mutations:{
           getRealName(state,val){
               state.userName = val.name
               sessionStorage.setItem("token",val.token)
               sessionStorage.setItem("userInfo",val.name)
           }
     }
}
