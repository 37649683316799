import Vue from 'vue'
import VueRouter from 'vue-router'

//以上如果导入太多组件会写很多行，而且效率也不行，如果只是登录页没必要导入太多组件
//所以采用懒加载
//const LoginNew=()=>import('../views/LoginNew.vue')
//进一步解决多个组件写多个导入的问题，封装成一个函数
const _import = file =>()=>import(`../views/${file}.vue`)

Vue.use(VueRouter)

const routes = [
  /*{
    path: '/login',
    component: _import("Login"),
  },*/
  {
    path: '/main',
    component: _import("Main"),
    meta:{
      title:"后台管理系统-主页"
    }
  },
  {
    path:"/",// 当请求什么都不填时跳转到login
    redirect:"/login_new"
  },
  {
    path: '/login_new',
    name:"login_new",
    component: _import("LoginNew"),
    meta:{
      title:"后台管理系统-登录页"
    }
  },
  {
    //匹配到main后加载mainnew组件，然后再重定向到main_new/home,由于main中有router-view,所以在main中显示
    path: '/main_new',
    component: _import("MainNew"),
    redirect:{name:"user"},
    children:[
      {
        path: 'user',
        name: "user",
        component: _import("user/User"),
        meta:{
          title:"后台管理系统-用户管理"
        }
      },
      {
        path: 'user/add',
        component: _import("user/UserAdd"),
      },
      {
        path: 'user/update',
        name: "UserUpdate",
        component: _import("user/UserUpdate"),
      },
      {
        path: 'home',
        name:"home",
        component: _import("Home"),
        meta:{
          title:"后台管理系统-主页"
        }
      },
      //分类路由
      {
        path: 'category',
        name: "category",
        component: _import("category/Category"),
        meta:{
          title:"后台管理系统-分类管理"
        }
      },
      //课程路由
      {
        path: 'course',
        name: "course",
        component: _import("course/Course"),
        meta:{
          title:"后台管理系统-课程管理"
        }
      },
      //课时路由
      {
        path: 'class_hour',
        name: "class_hour",
        component: _import("class_hour/ClassHour"),
        meta:{
          title:"后台管理系统-课时管理"
        }
      },
      //章节路由
      {
        path: 'chapter',
        name: "chapter",
        component: _import("chapter/Chapter"),
        meta:{
          title:"后台管理系统-章节管理"
        }
      },
      //文章路由
      {
        path: 'article',
        name: "article",
        component: _import("article/Article"),
        meta:{
          title:"后台管理系统-文章管理"
        }
      },
    ]
  },
 {
    path:"*",//没匹配到定位到404去
    component:_import("Error"),
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{//to将要访问的路径，from代表从哪个路径跳转而来，next是一个函数，表示放行
     //不是登录页
     if(to.path.indexOf("/login")==-1){
         const ak = sessionStorage.getItem("token");
          if(!ak){
             return next("/login_new");
          }
      }
     //标题设置
      if(to.meta.title){
        document.title = to.meta.title
      }else{
        document.title = "后台管理系统"
      }
      return next();
});
export default router
