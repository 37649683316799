import Vue from "vue"
import Vuex from "vuex"
import Login from "./login"

Vue.use(Vuex)

const Vx = new Vuex.Store({
   modules:{
     Login
   }
})
//防止刷新丢失状态
Vx.state.Login.userName = sessionStorage.getItem("userInfo") || null

export default Vx
