<template>
  <div id="app">
    <!--路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
   body{
      margin:0px;
      padding:0px;
      width:100%;
      height:100%;
  }
   #app{
      border:0px solid #f00;
      margin:0px;
      position: relative;
   }
</style>
