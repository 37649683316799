import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'
import store from "./store/index"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//axios全局配置
// 设置api请求的根路径
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//设置请求拦截器,发送之前要做的事,设置一个请求头
axios.interceptors.request.use(config=>{
      const access_token = sessionStorage.getItem("token");
      //如果有token，添加请求头
      if(access_token){
        config.headers['Authorization']= access_token;
      }
      return config;
});
//挂载到vue原型
Vue.prototype.$http = axios;
//网站地址
Vue.prototype.$siteUrl = process.env.VUE_APP_SITE_URL;


//Vue配置
Vue.config.productionTip = false

//实例化vue并挂载app组件
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
